import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Tech from "../../assets/product-tech-log.svg";
import SmartRegs from "../../assets/icon-dropdown-product-smart-regs.svg";
import Centrik from "../../assets/product-centrik.svg";
import DisplayCard from "../LandingPage/displayCard";
import * as pageStyles from "../../pages/page.module.less";
import * as styles from "./alsoInterestedIn.module.less";

const TechCard = {
  img: Tech,
  title: "Electronic Tech Log",
  text: "Automated flight entry, intuitive defect recording and fully integrated with your existing scheduling and maintenance systems. Gain a real-time view of your aircraft's status and utilisation",
  link: "techlog",
};

const CentrikCard = {
  img: Centrik,
  title: "Centrik",
  text: "Simple, intuitive operational management. Real-time integrated management of your operations.",
  link: "centrik",
};

const SmartRegsCard = {
  img: SmartRegs,
  title: "SmartRegs",
  text: "AI-powered regulatory compliance for aviation. Access over 120 international aviation regulatory frameworks with intelligent, context-aware assistance.",
  link: "smartregs",
};

const StyledTitle = styled.h3`
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.04px;
  color: #242d41;
  margin-top: 40px;
  padding-left: 12px;
  margin-bottom: 40px;
  @media screen and (max-width: 1200px) {
    font-size: 26px;
  }
  @media screen and (max-width: 991px) {
    font-size: 24px;
    margin-top: 10px;
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
    padding-left: 0;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
    text-align: center;
  }
`;

const Wrapper = styled.div`
  padding-bottom: 60px;
  position: relative;
  @media screen and (max-width: 768px) {
    padding-bottom: 45px;
  }
  @media screen and (max-width: 576px) {
    padding-bottom: 30px;
  }
`;

const SkewedBg = styled.div`
  background-image: linear-gradient(to top, #f8fcff 0%, #f0f5f9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  clip-path: polygon(0% 70%, 100% 33%, 100% 100%, 0% 100%);
  @media screen and (max-width: 768px) {
    background: #fff;
  }
`;

const AlsoInterestedIn = ({ page }) => {
  let cardsArray = [];
  switch (page) {
    case "tech":
      cardsArray = [CentrikCard, SmartRegsCard];
      break;
    case "task":
      cardsArray = [TechCard, SmartRegsCard];
      break;
    case "registry":
      cardsArray = [TechCard, SmartRegsCard];
      break;
    case "camo":
      cardsArray = [TechCard, CentrikCard];
      break;
    case "mel":
      cardsArray = [TechCard, CentrikCard];
      break;
    case "mel-trial":
      cardsArray = [CentrikCard, TechCard];
      break;
    case "smartregs":
      cardsArray = [TechCard, CentrikCard];
      break;
    default:
      cardsArray = [];
      break;
  }

  const cards = cardsArray.map((card, key) => (
    <Col key={key} md={6} className={styles.cardCol}>
      <AnimationOnScroll
        animateOnce
        animateIn="animate__fadeIn"
        offset={0}
        delay={key * 150}
      >
        <DisplayCard
          key={card.title}
          img={card.img}
          title={card.title}
          text={card.text}
          link={card.link}
          bottomPage
        />
      </AnimationOnScroll>
    </Col>
  ));
  return (
    <Wrapper>
      <SkewedBg />
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={10} md={12}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInUp"
              offset={0}
            >
              <StyledTitle>You may also be interested in</StyledTitle>
            </AnimationOnScroll>
          </Col>
          <Col lg={10} md={12}>
            <Row>{cards}</Row>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default AlsoInterestedIn;
